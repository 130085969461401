import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  location,
}) {
  const { site, markdownRemark } = data; // data.markdownRemark holds your post data
  const { siteMetadata } = site;

  const { frontmatter, html } = markdownRemark;
  const url = location.href ? location.href : "";
  const imageUrl = siteMetadata.siteUrl + frontmatter.thumbnail;
  const logoUrl = siteMetadata.siteUrl + siteMetadata.logo;

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: "en-US" }}>
        <title>
          {frontmatter.title} | {siteMetadata.title}
        </title>
        <meta name="description" content={frontmatter.metaDescription} />
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:description" content={frontmatter.metaDescription} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={url} />
        <meta
          property="og:image"
          content={frontmatter.thumbnail ? imageUrl : logoUrl}
        />

        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href={site.siteMetadata.theme.googleFontImport}
          rel="stylesheet"
        />
        <style type="text/css">{`
        body {
          font-family: ${site.siteMetadata.theme.googleFontName};
        }
        body.light a {
          color: ${site.siteMetadata.theme.linkColor};
        }
        body.light a:hover {
          color: ${site.siteMetadata.theme.linkHoverColor};
        }
        body.light .navigation a {
          color: ${site.siteMetadata.theme.navLinkColor};
        }
        body.light .navigation a:hover{
          color: ${site.siteMetadata.theme.navLinkHoverColor};
        }
        body.light { 
          --primary-color: ${site.siteMetadata.theme.primaryColor}; 
          --primary-text-color:${site.siteMetadata.theme.primaryTextColor};
          --primary-link-color:${site.siteMetadata.theme.linkColor};
          --primary-link-hover-color:${site.siteMetadata.theme.linkHoverColor};
          --featured-bg: ${site.siteMetadata.theme.primaryColor}; 
          --featured-text: #fff;
          --secondary-color: ${site.siteMetadata.theme.secondaryColor};
          --background: ${site.siteMetadata.theme.backgroundColor};
        }
        body.light .button.-primary {
          color: ${site.siteMetadata.theme.linkColor};
          background-color: ${site.siteMetadata.theme.ctaColor};
        }
        body.light .button.-primary:hover {
          color: ${site.siteMetadata.theme.linkColor};
          background-color: ${site.siteMetadata.theme.ctaHoverColor};
        }
        body.dark .button.-primary {
          color: ${site.siteMetadata.theme.darkModeLinkColor};
        }
        .button {
          font-weight: bold;
        }
    `}</style>
        <script>
          {`
            var script = document.createElement('script'); script.id = 'fathom';
            script.dataset.site = 'LFZLCTLP';
            script.src = "https://cdn.usefathom.com/script.js";
            document.getElementsByTagName('head')[0].appendChild(script);
          `}
        </script>

        <script>
          {`
              (function (s, e, n, d, er) {
                s['Sender'] = er;
                s[er] = s[er] || function () {
                  (s[er].q = s[er].q || []).push(arguments)
                }, s[er].l = 1 * new Date();
                var a = e.createElement(n),
                    m = e.getElementsByTagName(n)[0];
                a.async = 1;
                a.src = d;
                m.parentNode.insertBefore(a, m)
              })(window, document, 'script', 'https://cdn.sender.net/accounts_resources/universal.js', 'sender');
              sender('b985bb7a815249')
          `}
        </script>
      </Helmet>
      <div className="blog-post-container">
        <article className="post">
          {!frontmatter.thumbnail && (
            <div className="post-thumbnail">
              <h1 className="post-title">{frontmatter.title}</h1>
            </div>
          )}
          {!!frontmatter.thumbnail && (
            <div
              className="post-thumbnail"
              style={{ backgroundImage: `url(${frontmatter.thumbnail})` }}
            >
              <h1 className="post-title">{frontmatter.title}</h1>
            </div>
          )}
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </article>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        logo
        theme {
          primaryColor
          secondaryColor
          linkColor
          linkHoverColor
          backgroundColor
          navLinkColor
          navLinkHoverColor
          googleFontImport
          googleFontName
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
      }
      fields {
        gitAuthorTime(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
